import * as S from "./styles";
import WarrantyIcon from "Assets/Image/old_warranty.png";
import Image from 'next/image';

const SimpleWarranty = () => {
    return (
        <S.Wrapper data-testid="Warranty">
            <S.IconContainer>
                <Image
                    src={WarrantyIcon}
                    width={42}
                    height={42}
                    layout="responsive"
                    priority={true}
                    className="icon-image" alt="Warranty Icon" />
            </S.IconContainer>
            <S.TextContainer>
                <span>Garantia de 7 dias</span>. Caso não goste do nosso programa nós devolvemos o seu pagamento até 7 dias após a compra.
            </S.TextContainer>
        </S.Wrapper>
    );
};

export default SimpleWarranty;
