import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #7C7C7D;
    border-radius: 4px;
`;

export const IconContainer = styled.div`
    min-width: 42px;
    margin-right: 10px;

    .icon-image {
        min-width: 42px;
        max-width: 100%;
    }
`;

export const TextContainer = styled.div`
    font-family: sans-serif !important;
    font-size: 1rem !important;
    line-height: 1.2rem;
    color: #7C7C7D;

    span {
        font-family: sans-serif !important;
        font-size: 1rem !important;
        font-weight: 700;
    }

    @media ${(props) => props.theme.device.tablet} {
        font-size: 0.8rem !important;
        line-height: 1rem;

        span {
            font-size: 0.8rem !important;
        }
    }
`;
